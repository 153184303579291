.dashboardLayout{

  background-image: url(/images/background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  & > * {
    position: relative;
    z-index: 2;
  }

  &.loggedIn{
    background-color: #192134;
    color: var(--light);
    
    aside{
      background-color: var(--panelGray);
      padding-top: 3em;
      padding-bottom: 2em;
    }
    main{
      padding: 1em 2em 1em 1em;
      max-width: calc(100% - 240px - 1em);
    }
  }
  
  header{
    background-color: var(--panelGray);
    padding: 10px 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    height: 58px;

    & > a{
      display: flex;
    }

    .headerIcons{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
    }
  }

  .container{
    width: 100%;
    margin: 0 !important;
    min-height: calc(100vh - 58px);
    display: flex;
    gap: 1em;

    & > aside{
      width: 240px;
      position: relative;

      nav{
        position: sticky;
        top: 120px;
      }
    } 
  }
  
  main{
    flex: 1;
    width: 100%;
    min-height: calc(100vh - 58px);

    &.centralize{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.divider{
  margin: 1em 10%;
    height: 1px;
    width: 80%;
    background: #4d5875;
}