.login{
  background-color: var(--panelGray);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 1.5em;

  form label span{
    display: none;
  }

  & > div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a{
    color: white
  }
}